/*================================================================================================================
*                                            Users & Rights
* ==============================================================================================================*/

// users
export const GET_USERS_KEY = 'users';
export const UPSERT_USER_KEY = 'upsertUser';
export const DELETE_USER_KEY = 'deleteUser';
export const LINK_USER_GOOGLE_ACCOUNT = 'linkUserGoogleAccount';
export const UNLINK_USER_GOOGLE_ACCOUNT = 'unlinkUserGoogleAccount';

// rights
export const GET_RIGHTS_KEY = 'rights';
export const UPSERT_RIGHT_KEY = 'upsertRight';
export const DELETE_RIGHT_KEY = 'deleteRight';

// roles
export const GET_ROLES_KEY = 'roles';
export const UPSERT_ROLE_KEY = 'upsertRole';
export const DELETE_ROLE_KEY = 'deleteRole';

// password
export const REQUEST_PASSWORD_RESET_KEY = 'requestPasswordReset';
export const RESET_PASSWORD_KEY = 'resetPassword';

/*================================================================================================================
*                                            Master Data
* ==============================================================================================================*/

// unit of measure
export const GET_MEASUREMENT_UNITS_KEY = 'unitOfMeasures';
export const UPSERT_MEASUREMENT_UNIT_KEY = 'upsertUnitOfMeasure';
export const DELETE_MEASUREMENT_UNIT_KEY = 'deleteUnitOfMeasure';

// currency
export const GET_CURRENCIES_KEY = 'currencies';
export const UPSERT_CURRENCY_KEY = 'upsertCurrency';
export const DELETE_CURRENCY_KEY = 'deleteCurrency';

// fiscal year
export const GET_FISCAL_YEARS_KEY = 'fiscalYears';
export const UPSERT_FISCAL_YEAR_KEY = 'upsertFiscalYear';
export const DELETE_FISCAL_YEAR_KEY = 'deleteFiscalYear';

// region
export const GET_REGIONS_KEY = 'regions';
export const UPSERT_REGION_KEY = 'upsertRegion';
export const DELETE_REGION_KEY = 'deleteRegion';

// site
export const GET_SITES_KEY = 'sites';
export const UPSERT_SITE_KEY = 'upsertSite';
export const DELETE_SITE_KEY = 'deleteSite';

// store
export const GET_STORES_KEY = 'stores';
export const UPSERT_STORE_KEY = 'upsertStore';
export const DELETE_STORE_KEY = 'deleteStore';

// cost centre
export const GET_COST_CENTRES_KEY = 'costCentres';
export const UPSERT_COST_CENTRE_KEY = 'upsertCostCentre';
export const DELETE_COST_CENTRE_KEY = 'deleteCostCentre';

// department
export const GET_DEPARTMENTS_KEY = 'departments';
export const UPSERT_DEPARTMENT_KEY = 'upsertDepartment';
export const DELETE_DEPARTMENT_KEY = 'deleteDepartment';

// vendor
export const GET_VENDORS_KEY = 'vendors';
export const UPSERT_VENDOR_KEY = 'upsertVendor';
export const DELETE_VENDOR_KEY = 'deleteVendor';

// stock
export const GET_STOCK_KEY = 'stocks';
export const GET_STOCK_SEARCH_LIST_KEY = 'stockSearchList';
export const UPSERT_STOCK_KEY = 'upsertStock';
export const DELETE_STOCK_KEY = 'deleteStock';

// inventory
export const GET_INVENTORIES_KEY = 'inventories';
export const UPSERT_INVENTORY_KEY = 'upsertInventory';
export const DELETE_INVENTORY_KEY = 'deleteInventory';

// asset group
export const GET_ASSET_GROUPS_KEY = 'assetGroups';
export const UPSERT_ASSET_GROUP_KEY = 'upsertAssetGroup';
export const DELETE_ASSET_GROUP_KEY = 'deleteAssetGroup';

// gl account
export const GET_GL_ACCOUNTS_KEY = 'glAccounts';
export const GET_GL_ACCOUNT_SUMMARIES_BY_ACCOUNT_TYPE_KEY = 'glAccountsByAccountType';
export const GET_GL_ACCOUNTS_BY_ALLOW_ORDER_FORM_KEY = 'glAccountsByAllowOrderForm';

// gl account type
export const GET_GL_ACCOUNT_TYPES_KEY = 'glAccountTypes';
export const UPSERT_GL_ACCOUNT_TYPE_KEY = 'upsertGlAccountType';
export const DELETE_GL_ACCOUNT_TYPE_KEY = 'deleteGlAccountType';

// gl code
export const GET_GL_CODES_KEY = 'glCodes';
export const GET_GL_CODE_SUMMARIES_KEY = 'glCodeSummaries';

// news
export const GET_NEWS_KEY = 'news';
export const UPSERT_NEWS_KEY = 'upsertNews';
export const DELETE_NEWS_KEY = 'deleteNews';

// contact us
export const GET_CONTACT_US_KEY = 'contactUs';
export const UPSERT_CONTACT_US_KEY = 'upsertContactUs';
export const DELETE_CONTACT_US_KEY = 'deleteContactUs';

// value added tax
export const GET_VALUE_ADDED_TAXES_KEY = 'valueAddedTaxes';
export const UPSERT_VALUE_ADDED_TAX_KEY = 'upsertValueAddedTax';
export const DELETE_VALUE_ADDED_TAX_KEY = 'deleteValueAddedTax';

// employee
export const GET_EMPLOYEES_KEY = 'employees';
export const UPSERT_EMPLOYEE_KEY = 'upsertEmployee';
export const DELETE_EMPLOYEE_KEY = 'deleteEmployee';

// division
export const GET_DIVISIONS_KEY = 'divisions';
export const UPSERT_DIVISION_KEY = 'upsertDivision';
export const DELETE_DIVISION_KEY = 'deleteDivision';

// subdivision
export const GET_SUBDIVISIONS_KEY = 'subdivisions';
export const UPSERT_SUBDIVISION_KEY = 'upsertSubdivision';
export const DELETE_SUBDIVISION_KEY = 'deleteSubdivision';

/*================================================================================================================
*                                            Frequently Asked Question
* ==============================================================================================================*/

// frequently asked question
export const GET_FREQUENTLY_ASKED_QUESTION_KEY = 'frequentlyAskedQuestion';
export const UPSERT_FREQUENTLY_ASKED_QUESTION_KEY = 'upsertFrequentlyAskedQuestion';
export const DELETE_FREQUENTLY_ASKED_QUESTION_KEY = 'deleteFrequentlyAskedQuestion';

/*================================================================================================================
*                                            Asset Register
* ==============================================================================================================*/


// item
export const GET_ITEMS_KEY = 'items';
export const UPSERT_ITEM_KEY = 'upsertItem';
export const DELETE_ITEM_KEY = 'deleteItem';

// item purchase method
export const GET_ITEM_PURCHASE_METHODS_KEY = 'itemPurchaseMethods';
export const UPSERT_ITEM_PURCHASE_METHOD_KEY = 'upsertItemPurchaseMethod';
export const DELETE_ITEM_PURCHASE_METHOD_KEY = 'deleteItemPurchaseMethod';

// fleet fuel type
export const GET_FLEET_FUEL_TYPES_KEY = 'fleetFuelTypes';
export const UPSERT_FLEET_FUEL_TYPE_KEY = 'upsertFleetFuelType';
export const DELETE_FLEET_FUEL_TYPE_KEY = 'deleteFleetFuelType';

// fleet item type
export const GET_FLEET_ITEM_TYPES_KEY = 'fleetItemTypes';
export const UPSERT_FLEET_ITEM_TYPE_KEY = 'upsertFleetItemType';
export const DELETE_FLEET_ITEM_TYPE_KEY = 'deleteFleetItemType';

// fleet meter type
export const GET_FLEET_METER_TYPES_KEY = 'fleetMeterTypes';
export const UPSERT_FLEET_METER_TYPE_KEY = 'upsertFleetMeterType';
export const DELETE_FLEET_METER_TYPE_KEY = 'deleteFleetMeterType';

/*================================================================================================================
*                                                Capital
* ==============================================================================================================*/

// capital plan
export const GET_CAPITAL_PLAN_KEY = 'capitalPlan';
export const GET_CAPITAL_PLANS_KEY = 'capitalPlans';
export const UPSERT_CAPITAL_PLAN_KEY = 'upsertCapitalPlan';
export const DELETE_CAPITAL_PLAN_KEY = 'deleteCapitalPlan';
export const APPROVE_CAPITAL_PLANS_KEY = 'approveCapitalPlans';

// capital summary
export const GET_CAPITAL_SUMMARIES_KEY = 'capitalSummaries';

// capital lookup
export const GET_CAPITAL_LOOKUPS_KEY = 'capitalLookups';

// capital
export const GET_CAPITAL_KEY = 'capital';
export const GET_CAPITALS_KEY = 'capitals';
export const UPSERT_CAPITAL_KEY = 'upsertCapital';
export const DELETE_CAPITAL_KEY = 'deleteCapital';
export const REQUEST_CAPITAL_PLANS_APPROVAL_KEY = 'requestCapitalPlansApproval';

// capital transaction
export const GET_CAPITAL_TRANSACTIONS_KEY = 'capitalTransactions';
export const APPROVE_CAPITAL_TRANSACTIONS_KEY = 'approveCapitalTransactions';

// capital plan summary
export const GET_CAPITAL_PLAN_SUMMARIES_KEY = 'capitalPlanSummaries';

/*================================================================================================================
*                                                Requisition
* ==============================================================================================================*/

// procurement requisition
export const GET_PROCUREMENT_REQUISITION_KEY = 'procurementRequisition';
export const GET_PROCUREMENT_REQUISITIONS_KEY = 'procurementRequisitions';
export const UPSERT_PROCUREMENT_REQUISITION_KEY = 'upsertProcurementRequisition';
export const DELETE_PROCUREMENT_REQUISITION_KEY = 'deleteProcurementRequisition';

// procurement requisition line
export const GET_PROCUREMENT_REQUISITION_LINE_KEY = 'procurementRequisitionLine';
export const UPSERT_PROCUREMENT_REQUISITION_LINE_KEY = 'upsertProcurementRequisitionLine';
export const DELETE_PROCUREMENT_REQUISITION_LINE_KEY = 'deleteProcurementRequisitionLine';

// procurement requisition file
export const GET_PROCUREMENT_REQUISITION_SIGNED_URLS_KEY = 'procurementRequisitionSignedUrls';
export const GET_PROCUREMENT_REQUISITION_FILE_KEY = 'procurementRequisitionFile';
export const UPSERT_PROCUREMENT_REQUISITION_FILE_KEY = 'upsertProcurementRequisitionFile';
export const DELETE_PROCUREMENT_REQUISITION_FILE_KEY = 'deleteProcurementRequisitionFile';
export const DOWNLOAD_ALL_PROCUREMENT_REQUISITION_ATTACHMENTS = 'downloadAllProcurementRequisitionAttachments';

// stores requisition
export const GET_STORES_REQUISITION_KEY = 'storesRequisition';
export const GET_STORES_REQUISITIONS_KEY = 'storesRequisitions';
export const UPSERT_STORES_REQUISITION_KEY = 'upsertStoresRequisition';
export const DELETE_STORES_REQUISITION_KEY = 'deleteStoresRequisition';

// stores requisition line
export const GET_STORES_REQUISITION_LINE_KEY = 'storesRequisitionLine';
export const UPSERT_STORES_REQUISITION_LINE_KEY = 'upsertStoresRequisitionLine';
export const DELETE_STORES_REQUISITION_LINE_KEY = 'deleteStoresRequisitionLine';

/*================================================================================================================
*                                                Fresh Service
* ==============================================================================================================*/

export const CREATE_FRESH_SERVICE_TICKET = 'createFreshServiceTicket';

/*================================================================================================================
*                                                Google Cloud Storage Service
* ==============================================================================================================*/

export const DOWNLOAD_GOOGLE_CLOUD_STORAGE_FILE_KEY = 'getGoogleCloudStorageFile';
export const UPSERT_GOOGLE_CLOUD_STORAGE_FILE_KEY = 'upsertGoogleCloudStorageFile';
export const DELETE_GOOGLE_CLOUD_STORAGE_FILE_KEY = 'deleteGoogleCloudStorageFile';
export const GET_GOOGLE_CLOUD_STORAGE_LIST_DATA_KEY = 'getGoogleCloudStorageListData';

/*================================================================================================================
*                                                Auth
* ==============================================================================================================*/

export const LOGOUT_USERS_KEY = 'logoutUsers';
export const LOGOUT_ALL_USERS_KEY = 'logoutAllUsers';

/*================================================================================================================
*                                                Dev Tools
* ==============================================================================================================*/

// logging
export const GET_LOGS_KEY = 'logs';

// sync status
export const GET_SYNC_STATUS_KEY = 'syncStatus';
export const SET_SYNC_STATUS_KEY = 'setSyncStatus';
export const TRIGGER_FULL_SYNC_KEY = 'triggerFullSync';