import axios, { Canceler, CancelTokenStatic } from 'axios';
import { IDivision } from '../../../@types/model/masterData/division/division';
import { IDivisionUpsert } from '../../../@types/model/masterData/division/divisionUpsert';
import qs from 'qs';

export default class DivisionHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static masterDataDivisionGetList = async () : Promise<Array<IDivision>> => {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        const result = await axios.get(`${API_URL}/v1/MasterData/Division/GetList`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
        return result.data;
    }

    public static masterDataDivisionGetListByIds = async (divisionIds : Array<number> | null) : Promise<Array<IDivision>> => {
        if (this.cancelGetList) {
            this.cancelGetList('GET Canceled');
            this.cancelGetList = undefined;
        }

        const result = await axios.get(`${API_URL}/v2/MasterData/Division/GetList`, {
            params: {
                divisionIds,
            },
            paramsSerializer: qs.stringify,
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
        });
        return result.data;
    }

    public static divisionUpsert = async (divisionUpsert : IDivisionUpsert) : Promise<IDivision> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Division/Upsert`, divisionUpsert);
        return result.data;
    }

    public static divisionDelete = async (divisionId : number) : Promise<void> => {
        await axios.delete(`${API_URL}/v1/MasterData/Division/Delete`, {
            params: { divisionId },
        });
    }
}