import { Loader, NoRightsScreen, Screen } from '@zz2/zz2-ui';
import React, { Suspense, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import FreshServiceActions from '../../store/freshService/actions';

const PrivateRoute = (props : RouteProps) : React.ReactElement => {
    const dispatch = useAppDispatch();
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    const openSupportTicketDialog = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;
        if (!session.user.rights.length) return false;

        const rights = session.user.rights;
        return rights.some((n) => {
            /* Allow all sub urls if using the wildcard, otherwise match the url exactly */
            if (n.url?.includes('/*')) {
                return url.startsWith(n.url.split('/*')[0]);
            } else {
                const newUrl = url.split('/');
                if (newUrl[1] === 'requisitionManagement') {
                    return true;
                } else {
                    return n.url === url;
                }
            }
        });
    };

    const render = (routeProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) {
                return;
            }

            const Component = component;
            if (!hasMenuRight(routeProps.match.url)) {
                return <NoRightsScreen openSupportTicket={openSupportTicketDialog}/>;
            }

            return <Component {...routeProps} />;
        }

        return <Redirect to={{ pathname: '/logIn', state: { from: routeProps.location } }} />;
    };

    return (<Route {...rest} render={render} />);
};

{/* Route */}
const Home = lazy(() => import('../home/HomeScreen'));

{ /* Master Data */ }
const MasterDataManager = lazy(() => import('../masterData/MasterDataManager'));
const OrganizationMasterData = lazy(() => import('../masterData/organization/OrganizationMasterData'));
const CurrencyManager = lazy(() => import('../masterData/currency/CurrencyManager'));
const VendorManager = lazy(() => import('../masterData/vendor/VendorManager'));
const UnitOfMeasureManager = lazy(() => import('../masterData/unitOfMeasure/UnitOfMeasureManager'));
const FiscalYearManager = lazy(() => import('../masterData/fiscalYear/FiscalYearManager'));
const GlAccountTypeManager = lazy(() => import('../masterData/glAccountType/GlAccountTypeManager'));
const ContactUsManager = lazy(() => import('../masterData/contactUs/ContactUsManager'));
const ValueAddedTaxManager = lazy(() => import('../masterData/valueAddedTax/ValueAddedTaxManager'));
const DivisionManager = lazy(() => import('../masterData/division/DivisionManager'));
const EmployeeManager = lazy(() => import('../masterData/employee/EmployeeManager'));
const SubdivisionManager = lazy(() => import('../masterData/subdivision/SubdivisionManager'));
const DepartmentManager = lazy(() => import('../masterData/department/DepartmentManager'));
const CostCentreManager = lazy(() => import('../masterData/costCentre/CostCentreManager'));
const SiteManager = lazy(() => import('../masterData/site/SiteManager'));
const StoreManager = lazy(() => import('../masterData/store/StoreManager'));

{ /* Requisition Management */ }
const RequisitionManagement = lazy(() => import('../requisition/RequisitionManagement'));
const ProcurementRequisitionExport = lazy(() => import('../requisition/components/procurement/pdf/ProcurementRequisitionExport'));
const StoresRequisitionExport = lazy(() => import('../requisition/components/store/pdf/StoresRequisitionExport'));

{ /* Rights */ }
const RightsManager = lazy(() => import('../rights/rightsManagement/RightsManager'));
const UserManager = lazy(() => import('../rights/userManagement/UserManager'));

{/* Frequently Asked Questions */}
const FrequentlyAskedQuestionManager = React.lazy(() => import('../frequentlyAskedQuestions/FrequentlyAskedQuestionManager'));

{/* Development */}
const LogManager = React.lazy(() => import('../logging/LogManager'));

const Routes = () : React.ReactElement => {
    return (
        <Suspense fallback={<Screen><Loader/></Screen>}>
            <Switch>
                <Route path={'/faq'} exact component={FrequentlyAskedQuestionManager} />
                <Route
                    path={'/'} exact
                    render={() => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />
                {/* Home */}
                <PrivateRoute exact path='/home' component={Home} />

                {/* Master Data */}
                <PrivateRoute exact path='/masterData' component={MasterDataManager} />
                <PrivateRoute exact path='/masterData/organization' component={OrganizationMasterData} />
                <PrivateRoute exact path='/masterData/currency' component={CurrencyManager} />
                <PrivateRoute exact path='/masterData/vendor' component={VendorManager} />
                <PrivateRoute exact path='/masterData/unitOfMeasure' component={UnitOfMeasureManager} />
                <PrivateRoute exact path='/masterData/accountType' component={GlAccountTypeManager} />
                <PrivateRoute exact path='/masterData/fiscalYear' component={FiscalYearManager} />
                <PrivateRoute exact path='/masterData/contactUs' component={ContactUsManager} />
                <PrivateRoute exact path='/masterData/valueAddedTax' component={ValueAddedTaxManager} />
                <PrivateRoute exact path='/masterData/employee' component={EmployeeManager} />
                <PrivateRoute exact path='/masterData/division' component={DivisionManager} />
                <PrivateRoute exact path='/masterData/subdivision' component={SubdivisionManager} />
                <PrivateRoute exact path='/masterData/department' component={DepartmentManager} />
                <PrivateRoute exact path='/masterData/costCentre' component={CostCentreManager} />
                <PrivateRoute exact path='/masterData/site' component={SiteManager} />
                <PrivateRoute exact path='/masterData/store' component={StoreManager} />

                {/* Requisition Management */}
                <PrivateRoute exact path='/requisitionManagement' component={RequisitionManagement} />
                <PrivateRoute exact path='/requisitionManagement/procurement/export/:procurementId?' component={ProcurementRequisitionExport} />
                <PrivateRoute exact path='/requisitionManagement/stores/export/:storesId?' component={StoresRequisitionExport} />

                {/* Capital */}
                {/* <PrivateRoute exact auth={auth} path='/capital/dashboard' component={CapitalDashboard} /> */}
                {/* <PrivateRoute exact auth={auth} path='/capital/management' component={CapitalManagement} /> */}
                {/* <PrivateRoute auth={auth} path='/capitalPlan/:capitalId?' component={CapitalPlanManagement} /> */}
                {/* <PrivateRoute auth={auth} path='/capitalRequest/:capitalId?' component={CapitalApproval} /> */}
                {/* <PrivateRoute auth={auth} path='/capitalPlanApproval/:capitalId?' component={CapitalPlanApproval} /> */}
                {/* <PrivateRoute exact auth={auth} path='/assetRegister/itemManagement' component={ItemManagement} /> */}

                {/* Rights */}
                <PrivateRoute exact path='/rights/rightManager' component={RightsManager} />
                <PrivateRoute exact path='/rights/userManager' component={UserManager} />

                {/* Development */}
                <PrivateRoute exact path = '/development/tools' component={LogManager} />
                
            </Switch>
        </Suspense>
    );
};

export default Routes;