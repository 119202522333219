import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { red, orange, blue } from '@mui/material/colors';
import store from './store';
import './style/app.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import makeStyles from '@mui/styles/makeStyles';
import { HashRouter } from 'react-router-dom';
import EnvHelper from './service/helper/envHelper';
import materialTheme from './style/materialTheme';

if (process.env.NODE_ENV === 'production') {
    console.log('Production?', process.env.NODE_ENV);
} else {
    console.log('Dev');
}

const SNACKBAR_WIDTH = 400;

const snackBarProviderAnchor : SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const AppRoot = () : React.ReactElement => {
    const useStyles = makeStyles(() => ({
        success: { backgroundColor: materialTheme.palette.primary.main, width: SNACKBAR_WIDTH },
        error: { backgroundColor: red[600], width: SNACKBAR_WIDTH },
        warning: { backgroundColor: orange[600], width: SNACKBAR_WIDTH },
        info: { backgroundColor: blue[600], width: SNACKBAR_WIDTH },
    }));

    const classes = useStyles();

    const snackBarProviderClasses = {
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
    };

    document.title = EnvHelper.getEnvName();

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={store}>
                <HashRouter>
                    <SnackbarProvider
                        maxSnack={4}
                        anchorOrigin={snackBarProviderAnchor}
                        classes={snackBarProviderClasses}>
                        <App />
                    </SnackbarProvider>
                </HashRouter>
            </Provider>
        </LocalizationProvider>
    );
};

const rootDomElement = document.getElementById('root');

if (rootDomElement) {
    ReactDOM.render(<AppRoot />, rootDomElement);
}
