import { useMutation, useQuery } from '@tanstack/react-query';
import { IBaseRight } from '@zz2/zz2-ui';
import { IRightUpsert } from '../../../@types/model/auth/right/rightUpsert';
import RightHttpService from '../../../service/http/right/rightsHttpService';
import { DELETE_RIGHT_KEY, GET_RIGHTS_KEY, UPSERT_RIGHT_KEY } from '../queryConstants';
import { queryDeleteSuccessHandler, queryErrorHandler, querySuccessHandler } from '../queryStateHandlers';

export const useGetRights = () => {
    const query = useQuery<Array<IBaseRight>, Error>({
        queryKey: [GET_RIGHTS_KEY],
        queryFn: RightHttpService.getList,
        onError: queryErrorHandler('Error loading Places.'),
    });

    return query;
};

export const useHandleUpsertRight = () => {
    const result = useMutation<IBaseRight, Error, IRightUpsert>({
        mutationKey: [UPSERT_RIGHT_KEY],
        mutationFn: (upsert : IRightUpsert) => RightHttpService.upsert(upsert),
        onError: queryErrorHandler('Error creating/updating Right.'),
        onSuccess: querySuccessHandler(GET_RIGHTS_KEY, 'Right updated successfully.'),
    });

    return result;
};

export const useHandleDeleteRight = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_RIGHT_KEY],
        mutationFn: (rightId : number) => RightHttpService.delete(rightId),
        onError: queryErrorHandler('Error deleting Right.'),
        onSuccess: queryDeleteSuccessHandler(DELETE_RIGHT_KEY, GET_RIGHTS_KEY, 'Right inactivated successfully.'),
    });

    return result;
};