import { useMutation, useQuery } from '@tanstack/react-query';
import { IDepartment } from '../../../@types/model/masterData/department/department';
import { IDepartmentUpsert } from '../../../@types/model/masterData/department/departmentUpsert';
import DepartmentHttpService from '../../../service/http/masterData/departmentHttpService';
import { DELETE_DEPARTMENT_KEY, GET_DEPARTMENTS_KEY, UPSERT_DEPARTMENT_KEY } from '../queryConstants';
import { queryDeleteSuccessHandler, queryErrorHandler, querySuccessHandler } from '../queryStateHandlers';

export const useGetDepartments = (departmentIds : Array<number>) => {
    const query = useQuery<Array<IDepartment>, Error>({
        queryKey: [GET_DEPARTMENTS_KEY],
        queryFn: () => DepartmentHttpService.getList(departmentIds),
        onError: queryErrorHandler('Error loading Departments.'),
    });

    return query;
};

export const useGetAllDepartments = () => {
    const query = useQuery<Array<IDepartment>, Error>({
        queryKey: [GET_DEPARTMENTS_KEY, 'ALL'],
        queryFn: DepartmentHttpService.getAll,
        onError: queryErrorHandler('Error loading Departments.'),
    });

    return query;
};

export const useHandleUpsertDepartment = () => {
    const result = useMutation<IDepartment, Error, IDepartmentUpsert>({
        mutationKey: [UPSERT_DEPARTMENT_KEY],
        mutationFn: (upsert : IDepartment) => DepartmentHttpService.upsert(upsert),
        onError: queryErrorHandler('Error creating/updating Department.'),
        onSuccess: querySuccessHandler(GET_DEPARTMENTS_KEY, 'Department updated successfully.'),
    });

    return result;
};

export const useHandleDeleteDepartment = () => {
    return useMutation<void, Error, number>({
        mutationKey: [DELETE_DEPARTMENT_KEY],
        mutationFn: (departmentId : number) => DepartmentHttpService.delete(departmentId),
        onError: queryErrorHandler('Error deleting Department.'),
        onSuccess: queryDeleteSuccessHandler(DELETE_DEPARTMENT_KEY, GET_DEPARTMENTS_KEY, 'Department inactivated successfully.'),
    });
};